/*globals $ */
// import jQuery from 'jquery';
// const $ = jQuery;


class ImgLazyLoad {
  constructor(mainApp) {
    this.offsetTop = -200;
    if(mainApp.viewMode == 'sp') {
      this.offsetTop = -100;
    }
    this.elements = [];
    this.set();
  }


  set(){
    // #imgでdata-originalの属性があり、「set-lazy」クラスの付いていない要素を対象に設定
    // let $lazy = $('img[data-original]:not(.set-lazy)');
    let $lazy = $('.img-lazy:not(.set-lazy)');
    $lazy.each((i, el) => {
      let $el = $(el);
      this.elements.push($el);
      el.loaded = false;
      let src = $el.attr('data-original');
      // #「appeer」eventをセットした要素には「set-lazy」クラスを付与
      $el.addClass('set-lazy');
      $el.one('appeer',() => {
        if(!el.loaded){
          $('<img>')
            .one('load',() => {
              if($el.prop('tagName') != 'IMG') {
                $el.css('background-image', 'url("' + src + '")');
              } else {
                $el.attr('src', src);
              }

              el.loaded = true;
              $el.addClass('loaded');
            })
            .attr('src', src);
        }
      });
    });
  }


  update(scroll, windowH){
    $.each(this.elements, (i, el) => {
      // if($(el).is(':hidden')) return;
      if($(el).hasClass('loaded')) return;
      if(scroll > $(el).offset().top - windowH - this.offsetTop){
        $(el).trigger('appeer');
      }
    });
  }

}
export default ImgLazyLoad;
